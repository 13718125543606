<template>
  <div class="pagination__container">
    <!--  TODO: Hide pre/next page buttons on first/last page  -->
    <nuxt-link
      :class="{ 'opacity-0 pointer-events-none': isFirstPage }"
      :to="localePath(`/${path}/page/${Math.max(page - 1, 1)}`)"
      class="pagination__button pagination__button--underline gradient-text pagination__button--suffix"
    >
      {{ $t("previous") }}
    </nuxt-link>

    <ul class="pagination__items">
      <li v-if="page > 3">
        <nuxt-link :to="localePath(`/${path}`)" class="pagination__button">
          1
        </nuxt-link>
      </li>

      <li v-if="page > 4">
        <nuxt-link
          :to="localePath(`/${path}/page/${page - 3}`)"
          class="pagination__button"
        >
          ...
        </nuxt-link>
      </li>

      <li v-for="pageIndex in visiblePages" :key="`page-${pageIndex}`">
        <nuxt-link
          :class="{
            'pagination__button--underline gradient-text': pageIndex === page,
          }"
          :to="localePath(`/${path}/page/${pageIndex}`)"
          class="pagination__button"
        >
          {{ pageIndex }}
        </nuxt-link>
      </li>

      <li v-if="pagesCount - page > 3">
        <nuxt-link
          :to="localePath(`/${path}/page/${page + 3}`)"
          class="pagination__button"
        >
          ...
        </nuxt-link>
      </li>

      <li v-if="pagesCount - page > 2">
        <nuxt-link
          :class="{
            'pagination__button--underline gradient-text': pagesCount === page,
          }"
          :to="localePath(`/${path}/page/${pagesCount}`)"
          class="pagination__button"
        >
          {{ pagesCount }}
        </nuxt-link>
      </li>
    </ul>

    <nuxt-link
      :class="{ 'opacity-0 pointer-events-none': isLastPage }"
      :to="localePath(`/${path}/page/${page + 1}`)"
      class="pagination__button pagination__button--underline gradient-text pagination__button--suffix pagination__button--next"
    >
      {{ $t("next") }}
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  page: number;
  pagesCount: number;
  path: string;
}>();

const localePath = useLocalePath();

const visiblePages = computed(() => {
  const arr = [
    props.page - 2,
    props.page - 1,
    props.page,
    props.page + 1,
    props.page + 2,
  ];

  return arr.filter((val) => val > 0 && val <= props.pagesCount);
});

const isFirstPage = computed(() => props.page === 1);
const isLastPage = computed(() => props.page >= props.pagesCount);
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.pagination__container {
  @apply relative w-full flex flex-wrap md:flex-nowrap justify-between pt-16;
}

.pagination__items {
  @apply flex justify-center items-center w-full md:w-auto py-7 mt-4 md:mt-0;
  order: 2;

  @screen md {
    order: 1;
  }

  .pagination__single {
    $size: 2rem;
    @apply flex items-center justify-center mx-3 cursor-pointer;
    width: $size;
    height: $size;
    transition: 0.4s;

    &--active {
      @apply pointer-events-none;
    }
  }
}

.pagination__button {
  @apply relative flex items-center mx-3;

  &--underline {
    @apply relative self-center leading-9;

    &::before {
      @apply absolute left-0 bottom-0 w-full;
      content: "";
      height: 1px;
      @include gradient;
    }
  }

  &--suffix {
    &::after {
      @screen md {
        @apply inline-block ml-3;
        content: "page";
      }
    }
  }

  &--next {
    order: 1;

    @screen md {
      order: 2;
    }
  }
}
</style>
