<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4788 10.8335H3.33301V9.16683H13.4788L8.81217 4.50016L9.99967 3.3335L16.6663 10.0002L9.99967 16.6668L8.81217 15.5002L13.4788 10.8335Z"
      fill="url(#paint0_linear_649_1142)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_649_1142"
        gradientUnits="userSpaceOnUse"
        x1="3.33301"
        x2="17.7739"
        y1="3.3335"
        y2="4.66629"
      >
        <stop stop-color="#F9D416" />
        <stop offset="0.536458" stop-color="#F08379" />
        <stop offset="1" stop-color="#9B4765" />
      </linearGradient>
    </defs>
  </svg>
</template>
