<template>
  <figure class="single-item__container">
    <picture class="item__picture">
      <slot name="image" />
    </picture>

    <figcaption class="item__caption">
      <header v-if="$slots['caption']" class="caption__header">
        <slot name="caption" />
      </header>

      <h3 class="caption__title">
        <slot name="title" />
      </h3>
    </figcaption>

    <nuxt-link
      v-if="$slots['link-text']"
      :to="localePath(`/${url}`)"
      class="item__link"
    >
      <slot name="link-text" />

      <icon-arrow-gradient class="link__icon" />
    </nuxt-link>
  </figure>
</template>

<script lang="ts" setup>
import IconArrowGradient from "~/modules/shared/components/icons/IconArrowGradient.vue";

defineProps<{
  url: string;
}>();

const localePath = useLocalePath();
</script>

<style lang="scss" scoped>
.single-item__container {
  @apply flex flex-col;
}

.item__picture {
  @apply relative block w-full mb-16 overflow-hidden border-primary-100;
  aspect-ratio: 16 / 9;
  border-width: 1px;
  border-radius: 2.4rem;

  &::before {
    @apply absolute top-0 left-0 w-full h-full bg-primary-75;
    content: "";
    z-index: -1;
  }
}

.item__caption {
  flex: 1;

  .caption__title {
    @apply text-subtitle;
  }
}

.caption__header {
  @apply flex flex-col md:flex-row md:items-center mb-6 text-body-sm text-primary-450;
}

.item__link {
  @apply flex items-center mt-8 text-body-xs;

  &:hover {
    .link__icon {
      @apply ml-6;
    }
  }

  .link__icon {
    @apply ml-4;
    transition: 0.4s;
  }
}
</style>
